import { AxiosRequestConfig } from 'axios'

import { CreateNotificationPayload, DataParams, UpdateNotificationData } from './types'

const getNotifications = (): AxiosRequestConfig => ({
  url: '/notifications',
  method: 'GET',
})

const createNotification = (payload: CreateNotificationPayload): AxiosRequestConfig => ({
  url: '/notifications',
  method: 'POST',
  data: { notification: payload },
})

const updateNotification = (data: UpdateNotificationData): AxiosRequestConfig => ({
  url: `/notifications/${data.id}`,
  method: 'PUT',
  data: { notification: data.payload },
})

const deleteNotification = (id: number): AxiosRequestConfig => ({
  url: `/notifications/${id}`,
  method: 'DELETE',
})

const fetchPushUserCount = ({
  visitsOperator, visitsValue, dateFrom, dateTo, rewardId, rewardInverted, tagIds, allTagsAreRequired
}: DataParams): AxiosRequestConfig => {
  const params = {
    ...(visitsOperator ? { visitsOperator } : {}),
    ...(visitsValue !== undefined ? { visitsValue } : {}),
    ...(dateFrom ? { dateFrom } : {}),
    ...(dateTo ? { dateTo } : {}),
    ...(rewardId ? { rewardId } : {}),
    ...(rewardInverted ? { rewardInverted } : {}),
    ...(tagIds ? { tagIds } : {}),
    ...(allTagsAreRequired ? { allTagsAreRequired } : {}),
  };

  return {
    url: 'notifications/user-count',
    method: 'GET',
    params,
  };
};

const getNotificationsUsers = (notificationId: number): AxiosRequestConfig => ({
  url: `/notifications/${notificationId}/users`,
  method: 'GET',
})

export { getNotifications, createNotification, updateNotification, deleteNotification, fetchPushUserCount, getNotificationsUsers }
