import React, { useState, useEffect } from 'react'

import { ERRORS } from 'consts'
import { useApiCall } from 'hooks'
import { snackbar } from 'components'

import { getStores } from './api'
import { DefaultTable } from '../../components'
import { GetStoresResponse, ParametersDownload, Stores } from './types'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'
import { Moment } from 'moment'

const useStyles = makeStyles({
  title: {
    color: '#e78923',
  },
  divider: {
    backgroundColor: '#e78923',
  },
})

const columns = [
  { title: 'ID', field: 'store_id' },

  { title: 'Título', render: (rowData: Stores) => rowData.store.name },

  { title: 'Cantidad', field: 'count_store' },
]

interface propsUsersStatus {
  startDate: Moment
  endDate: Moment
  storeId: number
}

const StoresTopFive: React.FC<propsUsersStatus> = ({ startDate, endDate, storeId }) => {
  const [data, setData] = useState<Stores[] | []>([])
  const [requestUsersTags, isLoading] = useApiCall<ParametersDownload, GetStoresResponse>(() =>
    getStores({ startDate, endDate, storeId }),
  )

  useEffect(() => {
    requestUsersTags({ startDate, endDate, storeId })
      .then(resp => {
        setData(resp.data)
      })
      .catch(error => {
        setData([])
        snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
      })
  }, [])

  const classes = useStyles()

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" component="h2" className={classes.title}>
          Registro de visitas por locales
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <DefaultTable
          title="Registro de visitas por locales"
          columns={columns}
          data={data}
          isLoading={isLoading}
          options={{ pageSize: 5, toolbar: false }}
        />
      </Grid>
    </>
  )
}

export { StoresTopFive }
