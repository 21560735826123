import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'

import { MultiSelect } from "react-multi-select-component";
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputLabel, Select, MenuItem, Typography, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, LinearProgress } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker } from '@material-ui/pickers'
import Divider from '@material-ui/core/Divider';

import { STRINGS } from 'consts'
import { useApiCall, useCheckFormErrors } from 'hooks'
import { Dialog, UploadButton, snackbar } from 'components'
import { ManualReward, UpdateManualRewardResponse, UpdateManualRewardData } from '../../types'
import { updateManualReward } from '../../api'
import { Tag } from './../../../Tags/types'
import { useStyles } from './styles'
import { PrizeCategory } from 'consts/prize';
import { DataParams, DataResponse } from 'features/Main/Notifications/types';
import { fetchPushUserCount } from 'features/Main/Notifications/api';
import RewardFilter from 'features/Main/Notifications/components/filters/RewardFilter';
import DateRangeFilter from 'features/Main/Notifications/components/filters/DateRangeFilter';
import VisitsFilter from 'features/Main/Notifications/components/filters/VisitsFilter';

const rules = {
	title: [
		{
			validate: (title: string) => title.length <= 25,
			message: 'No puede superar los 25 caracteres',
		},
	],
	shortTitle: [
		{
			validate: (shortTitle: string) => shortTitle.length <= 22,
			message: 'No puede superar los 22 caracteres',
		},
	],
	additionalText: [
		{
			validate: (value: string) => (value && value.length <= 100) || !value.length,
			message: 'No puede superar los 100 caracteres',
		},
	],
	pushTitle: [
		{
			validate: (title?: string) => title ? title.length <= 50 : true,
			message: 'No puede superar los 50 caracteres',
		},
	],
	pushDescription: [
		{
			validate: (description?: string) => description ? description.length <= 192 : true,
			message: 'No puede superar los 192 caracteres',
		},
	],
	toteatBenefitId: [
		{
			validate: (value: string, data: any) => {
				const { toteatEnabled, category } = data
				if (toteatEnabled && category === PrizeCategory.Checkout) {
					return value.trim().length > 0
				}
				return true
			},
			message: 'El ID Beneficio Toteat es requerido',
			validateEmpty: true
		}
	],
	skusList: [
		{
			validate: (skusList: string[], data: any) => {
				const { toteatEnabled, category } = data
				if (toteatEnabled && category === PrizeCategory.General) {
					return skusList.length > 0
				}
				return true
			},
			message: 'El SKU es requerido',
		}
	],
	visitsFilter: [
		{
		validate: (visitsFilter: string | null) =>
			visitsFilter == null ? true : parseInt(visitsFilter) <= 1000,
		message: 'Máximo permitido: 1000',
		},
	]
}

interface UpdateManualRewardDialogProps {
	handleClose: () => void
	manualRewardToUpdate: ManualReward
	onDone: (newManualReward: ManualReward) => void
	tags: Tag[]
  calcUsersByTags: (tagsSelected: any[], allTagsAreRequired?: boolean) => Promise<number>
}

const categories = Object.values(PrizeCategory)

const isEcardEnabled = process.env.REACT_APP_ECARD_ENABLED === "true";

const UpdateManualRewardDialog: React.FC<UpdateManualRewardDialogProps> = ({
	handleClose,
	onDone,
	manualRewardToUpdate,
	tags,
	calcUsersByTags
}) => {
	const classes = useStyles()

	const [toteatBenefitId, setToteatBenefitId] = useState<string>(manualRewardToUpdate.toteatBenefitId || '')
	const [currentSku, setCurrentSku] = useState<string>('')
	const [skusList, setSkusList] = useState<string[]>(manualRewardToUpdate.skus ? manualRewardToUpdate.skus.map(s => s.sku) : [])
	const [storesEnabled, setStoresEnabled] = useState(manualRewardToUpdate.storesEnabled)
	const [tucanEnabled, setTucanEnabled] = useState(manualRewardToUpdate.tucanEnabled)
	const [toteatEnabled, setToteatEnabled] = useState(manualRewardToUpdate.toteatEnabled)
	const [category, setCategory] = useState<PrizeCategory | undefined>(manualRewardToUpdate.category)
	const [title, setTitle] = useState(manualRewardToUpdate.title)
	const [shortTitle, setShortTitle] = useState(manualRewardToUpdate.shortTitle)
	const [icon, setIcon] = useState<File | undefined>(undefined)
	const [iconPreview, setIconPreview] = useState(manualRewardToUpdate.iconUrl)
	const [image, setImage] = useState<File | undefined>(undefined)
	const [imagePreview, setImagePreview] = useState(manualRewardToUpdate.imageUrl)
	const [pushMessage, setPushMessage] = useState(manualRewardToUpdate.pushMessage ? true : false);
	const [pushTitle, setPushTitle] = useState(manualRewardToUpdate.pushTitle)
	const [pushDescription, setPushDescription] = useState(manualRewardToUpdate.pushDescription)
	const [active, setActive] = useState(manualRewardToUpdate.active ? true : false);
	const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [usersByTags, setUsersByTags] = useState(0)
	const [expiration, setExpiration] = useState<Moment | null>(moment(manualRewardToUpdate.expiration))
	const [additionalText, setAdditionalText] = useState(manualRewardToUpdate.additionalText ? manualRewardToUpdate.additionalText : '')
	const firstExpirationDate = moment().format('YYYY-MM-DD hh:mm')
	const [allTagsAreRequired, setAllTagsAreRequired] = useState(manualRewardToUpdate.allTagsAreRequired)
	const [visitsFilter, setVisitsFilter] = useState<string | null>(
		manualRewardToUpdate.visitsValue == null ? null : manualRewardToUpdate.visitsValue,
	  )
	  const [visitsOperator, setVisitsOperator] = useState<'==' | '>' | '<'>(
		manualRewardToUpdate.visitsOperator,
	  )
	  const [startDate, setStartDate] = useState<Moment | null>(
		manualRewardToUpdate.dateFrom ? moment(manualRewardToUpdate.dateFrom) : null,
	  )
	  const [endDate, setEndDate] = useState<Moment | null>(
		manualRewardToUpdate.dateTo ? moment(manualRewardToUpdate.dateTo) : null,
	  )
	  const [rewardId, setRewardId] = useState<string>(manualRewardToUpdate.rewardId ?? '')
	  const [invertedLogic, setInvertedLogic] = useState<boolean>(manualRewardToUpdate.rewardInverted)
	  const [userCount, setUserCount] = useState<number | null>(null)

	const [requestData, isRequestingData] = useApiCall<DataParams, DataResponse>(fetchPushUserCount)

	const [updateManualRewardApi, isLoading] = useApiCall<UpdateManualRewardData, UpdateManualRewardResponse>(updateManualReward)
	const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

	useEffect(() => {
		if (!manualRewardToUpdate || !manualRewardToUpdate.tags) return
    
    const localTagsSelected = manualRewardToUpdate.tags.map(el => ({ label: el.title, value: el.id }))
    setTagsSelected(localTagsSelected)

    const getUsersByTags = async () => {
      return await calcUsersByTags(localTagsSelected, allTagsAreRequired);
    }
    getUsersByTags().then(el => {
      setUsersByTags(el)
    })
	}, []);

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))    
  }, [tagsSelected])

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)    
    setUsersByTags(total)
  }
  
	const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setTitle(e.target.value)
	}

	const handleShortTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setShortTitle(e.target.value)
	}

	const handleIconChange = (file: File) => {
		setIconPreview(URL.createObjectURL(file))
		setIcon(file)
	}

	const handleImageChange = (file: File) => {
		setImagePreview(URL.createObjectURL(file))
		setImage(file)
	}

	const handleChangePushMessage: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPushMessage(!pushMessage);
		if (!pushMessage) {
			setPushTitle('')
			setPushDescription('')
		}
	}

	const handleChangeActive: React.ChangeEventHandler<HTMLInputElement> = e => {
		setActive(!active);
	}

	const handleChangePushTitle: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPushTitle(e.target.value)
	}

	const handleChangepushDescription: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPushDescription(e.target.value)
	}

	const handleDateChange = (newDate: Moment | null) => {
		setExpiration(newDate)
	}

	const handleAdditionalTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setAdditionalText(e.target.value)
	}

	const handleToteatBenefitIdChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    	setToteatBenefitId(e.target.value)
  	}

	const handleCurrentSkuChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setCurrentSku(e.target.value)
	}

	const handleAddSku = () => {
		if (currentSku.trim()) {
		setSkusList([...skusList, currentSku.trim()])
		setCurrentSku('')
		}
	}

	const handleRemoveSku = (skuToRemove: string) => {
		setSkusList(skusList.filter(sku => sku !== skuToRemove))
	}

	const getRequiredFields = () => {
		const baseRequiredFields = ['title', 'shortTitle', 'additionalText']
		const pushTitleRequiredFields = ['pushTitle', 'pushDescription']

		if (pushMessage) {
			baseRequiredFields.push(...pushTitleRequiredFields)
		}

		if (toteatEnabled) {
			if (category === PrizeCategory.General) {
				baseRequiredFields.push('skusList')
			} else if (category === PrizeCategory.Checkout) {
				baseRequiredFields.push('toteatBenefitId')
			}
		}

		return baseRequiredFields
	}

	const fields = { title, shortTitle, additionalText, pushMessage, pushTitle, pushDescription, toteatEnabled, toteatBenefitId, skusList, category, visitsFilter }
	const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, getRequiredFields())
	const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

	const onUpdateManualReward = async () => {
		const manualReward = {
			title,
			shortTitle,
			icon,
			image,
			pushMessage,
			pushTitle,
			pushDescription,
			active,
			tagsSelected,
			expiration: expiration?.toISOString(),
			additionalText,
			allTagsAreRequired,
			...(visitsFilter !== null ? { visitsValue: visitsFilter } : {}),
			visitsOperator,
			...(startDate?.toISOString() ? { dateFrom: startDate?.toISOString() } : {}),
			...(endDate?.toISOString() ? { dateTo: endDate?.toISOString() } : {}),
			...(rewardId ? { rewardId } : {}),
			rewardInverted: invertedLogic ,
			category,
			storesEnabled,
			tucanEnabled,
			toteatEnabled,
			skus: skusList,
			toteatBenefitId,
		}

		const payload = {
			id: manualRewardToUpdate.id,
			shortTitle: manualRewardToUpdate.shortTitle,
			payload: manualReward,
			pushMessage: pushMessage,
			active: active,
		}
		try {
			const { manualReward: updatedManualReward } = await updateManualRewardApi(payload)
			onDone(updatedManualReward)
		} catch (err) {
			console.error(err)
			snackbar.show('No se pudo editar premio. Intente de nuevo.')
		}
	}

	const handleFetchPushUserCount = async () => {
		try {
		  const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
		  
		  const data = await requestData({
			visitsValue:
			  visitsFilter !== null && visitsFilter.length ? parseInt(visitsFilter) : undefined,
			visitsOperator,
			dateFrom: startDate?.toISOString() || undefined,
			dateTo: endDate?.toISOString() || undefined,
			rewardId: rewardId ? parseInt(rewardId) : undefined,
			rewardInverted: invertedLogic,
			tagIds: tagsSelectedId,
			allTagsAreRequired,
		  })
	
		  setUserCount(data.count)
		} catch (err) {
		  console.error(err)
		  snackbar.show('No se pudo calcular la cantidad de usuarios. Intente de nuevo.')
		}
	  }
	
	  useEffect(() => {
		handleFetchPushUserCount()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [
		visitsFilter,
		visitsOperator,
		startDate,
		endDate,
		rewardId,
		invertedLogic,
		tagsSelected,
		allTagsAreRequired,
	  ])	

	return (
		<Dialog
			title="Editar reward manual"
			isOpen
			showActions
			onCancel={handleClose}
			isLoading={isLoading}
			okButtonText="Editar"
			okButtonProps={{ disabled: isSubmitDisabled }}
			onAccept={onUpdateManualReward}
		>
			<Grid container spacing={3}>
				<Grid item xs={11}>
					<Typography variant="h6">Integracion con Partner</Typography>
				</Grid>

				<Grid item xs={6}>
					<FormControlLabel
						control={
							<Checkbox onChange={e => setStoresEnabled(e.target.checked)} checked={storesEnabled} />
						}
						label="Disponible en locales"
					/>
				</Grid>

				<Grid item xs={6}>
					<InputLabel className={classes.multiselectTitle}>Categoria</InputLabel>
					<FormControl className={classes.fullWidth}>
						<Select
							value={category}
							onChange={(e) => setCategory(e.target.value as PrizeCategory)}>
							{categories.map(option => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={6}>
					<FormControlLabel
						control={
							<Checkbox onChange={e => setTucanEnabled(e.target.checked)} checked={tucanEnabled} />
						}
						label="Disponible en Tucan"
					/>
				</Grid>
				<Grid item xs={6}>
					<FormControlLabel
						control={
							<Checkbox onChange={e => setToteatEnabled(e.target.checked)} checked={toteatEnabled} />
						}
						label="Disponible en Toteat"
					/>
				</Grid>

				{toteatEnabled && (
					<>
						<Grid item xs={6}>
							<Grid container spacing={2}>
								<Grid item xs={9}>
									<TextField
										label="SKU"
										value={currentSku}
										fullWidth
										required={toteatEnabled && category === PrizeCategory.General}
										error={errors.skusList.hasError}
										helperText={errors.skusList.message}
										onChange={handleCurrentSkuChange}
										disabled={isLoading}
										onKeyPress={e => {
											if (e.key === 'Enter') {
												e.preventDefault()
												handleAddSku()
											}
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<IconButton
										size="medium"
										onClick={handleAddSku}
										disabled={!currentSku.trim() || isLoading}
									>
										<AddIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="ID Beneficio Toteat"
								value={toteatBenefitId}
								fullWidth
								required={toteatEnabled && category === PrizeCategory.Checkout}
								error={errors.toteatBenefitId.hasError}
								helperText={errors.toteatBenefitId.message}
								onChange={handleToteatBenefitIdChange}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={6}>
							<List>
								{skusList.map((sku, index) => (
									<ListItem key={index}>
										<ListItemText primary={sku} />
										<ListItemSecondaryAction>
											<IconButton onClick={() => handleRemoveSku(sku)}>
												<DeleteIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						</Grid>
					</>
				)}

				<Grid item xs={12}>
					<Divider variant="middle" />
				</Grid>
				<Grid item xs={11}>
					<TextField
						label="Título"
						value={title}
						fullWidth
						onChange={handleTitleChange}
						disabled={isLoading}
						required
						error={errors.title.hasError}
						helperText={errors.title.message}
						inputProps={{ maxLength: 25 }}
					/>
				</Grid>
				<Grid item xs={11}>
					<TextField
						label="Descripcion"
						value={shortTitle}
						fullWidth
						onChange={handleShortTitleChange}
						disabled={isLoading}
						required
						error={errors.shortTitle.hasError}
						helperText={errors.shortTitle.message}
						inputProps={{ maxLength: 60 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Texto adicional"
						value={additionalText}
						fullWidth
						onChange={handleAdditionalTextChange}
						disabled={isLoading}
						required
						error={errors.additionalText.hasError}
						helperText={errors.additionalText.message}
					/>
				</Grid>
				{ !isEcardEnabled && 
				<Grid item xs={12} sm={6}>
					<div className={classes.iconUploadContainer}>
						<UploadButton
							id="manualReward-icon-upload-button"
							accept=".jpg, .jpeg, .png"
							label="Subir ícono"
							onChange={handleIconChange}
						/>
						<div className={classes.iconUploadPreviewContainer}>
							<img src={iconPreview} alt="" className={classes.iconPreview} />
						</div>
					</div>
				</Grid>
				}
				<Grid item xs={11}><Divider variant="middle" /></Grid>
				<Grid item xs={11}>
					<InputLabel className={classes.multiselectTitle}>Tag</InputLabel>
					<FormControl className={classes.fullWidth}>
						<MultiSelect
							options={tagsForSelect}
							value={tagsSelected}
							onChange={handleSetTags}
							labelledBy={"Seleccionar Tags"}
							hasSelectAll={false}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={<Checkbox onChange={handleAllTagsAreRequiredChange}
							checked={allTagsAreRequired}
						/>}
						label="Sólo usuarios con todos los tags seleccionados"
					/>
				</Grid>
				<Grid item xs={12}>
				<Typography variant="subtitle1" className={classes.dialogSubTitle}>
					Rango de visitantes
				</Typography>
				<Grid item xs={12}>
					<VisitsFilter
					visitsFilter={visitsFilter}
					setVisitsFilter={setVisitsFilter}
					visitsOperator={visitsOperator}
					setVisitsOperator={setVisitsOperator}
					error={errors.visitsFilter.hasError}
					helperText={errors.visitsFilter.message}
					/>
				</Grid>
				<Grid item xs={12}>
					<DateRangeFilter
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					dateFormat={STRINGS.DATE_FORMAT}
					/>
				</Grid>
				</Grid>

				<Grid item xs={12}>
				<Typography variant="subtitle1" className={classes.dialogSubTitle}>
					Premio
				</Typography>
				<RewardFilter
					rewardId={rewardId}
					setRewardId={setRewardId}
					invertedLogic={invertedLogic}
					setInvertedLogic={setInvertedLogic}
				/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<DatePicker
						value={expiration}
						onChange={handleDateChange}
						autoOk
						disablePast
						format={STRINGS.DATE_FORMAT}
						minDate={firstExpirationDate}
						initialFocusedDate={firstExpirationDate}
						disabled={isLoading}
						style={{ width: '100%' }}
						minDateMessage="La fecha de vencimiento no puede ser anterior a hoy"
						required
					/>
				</Grid>
				{ !isEcardEnabled && 
				<Grid item xs={12} sm={12}>
					<div className={classes.iconUploadContainer}>
						<UploadButton
							id="manualReward-image-upload-button"
							accept=".jpg, .jpeg, .png"
							label="Subir imagen popup"
							onChange={handleImageChange}
							maxSizeInMB={2}
						/>
						<div className={classes.iconUploadPreviewContainer}>
							<img src={imagePreview} alt="" className={classes.iconPreview} />
						</div>
					</div>
				</Grid>
				}
				<Grid item xs={11}>
					<FormControlLabel control={<Checkbox onChange={handleChangePushMessage} checked={pushMessage} />} label="Sale con push?" />
				</Grid>
				{pushMessage && (
					<>
						<Grid item xs={11}>
							<TextField
								label="Titulo push"
								value={pushTitle}
								fullWidth
								required
								error={errors.pushTitle.hasError}
								helperText={errors.pushTitle.message}
								onChange={handleChangePushTitle}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={11}>
							<TextField
								label="Descripción push"
								value={pushDescription}
								fullWidth
								required
								error={errors.pushDescription.hasError}
								helperText={errors.pushDescription.message}
								onChange={handleChangepushDescription}
								disabled={isLoading}
							/>
						</Grid>
					</>
				)}
				<Grid item xs={11}>
					<FormControlLabel control={<Checkbox onChange={handleChangeActive} checked={active} />} label="Activar Reward" />
				</Grid>
				<Grid item xs={12}>
                 <InputLabel>
                   {isRequestingData ? (
                     <LinearProgress />
                   ) : (
                     <div className={classes.tootlTipUsers}>
                       Esta acción impactará a {userCount} usuarios
                     </div>
                   )}
                 </InputLabel>
               </Grid>
      		</Grid>
		</Dialog>
	)
}

export { UpdateManualRewardDialog }
