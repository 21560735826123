// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react'
import MaterialDrawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import SvgIcon from '@material-ui/core/SvgIcon'
import PersonIcon from '@material-ui/icons/Person'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import LinkIcon from '@material-ui/icons/Link'
import StoreIcon from '@material-ui/icons/Store'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import NotificationsIcon from '@material-ui/icons/Notifications'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import { NavLink } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AssignmentIcon from '@material-ui/icons/Assignment'
import StylesIcon from '@material-ui/icons/Style'

import { ReactComponent as AutomationSvg } from 'assets/icons/automatic.svg'
import { Version } from 'components/Version'

import { useStyles } from './styles'
import { getVersion } from 'components/Version/api'
import { useApiCall } from 'hooks'

interface Path {
  pathname: string
  search?: string
  hash?: string
  state?: Record<string, unknown>
}

interface Items {
  label: string
  link?: string | Path
  icon?: typeof SvgIcon
}

interface DrawerOption {
  label: string
  link?: string | Path
  icon: typeof SvgIcon
  items?: Items[]
  showCondition: boolean
}

const AutomationIcon = () => (
  <SvgIcon>
    <AutomationSvg />
  </SvgIcon>
)

const isEcard = process.env.REACT_APP_ECARD_ENABLED === "true" 

const drawerOptions: DrawerOption[] = [
  {
    label: 'Usuarios',
    link: '/users',
    icon: PersonIcon,
    showCondition: true,
  },
  {
    label: 'Links mobile',
    link: {
      pathname: '/drawer-items',
    },
    icon: LinkIcon,
    showCondition: !isEcard,
  },
  {
    label: 'Locales',
    link: {
      pathname: '/stores',
    },
    icon: StoreIcon,
    showCondition: true,
  },
  {
    label: 'Membresias',
    link: {
      pathname: '/memberships',
    },
    icon: CardMembershipIcon,
    showCondition: true,
  },
  {
    label: 'Promos',
    link: {
      pathname: '/coupons',
    },
    icon: ReceiptIcon,
    showCondition: !isEcard,
  },
  {
    label: 'Rewards manuales',
    link: {
      pathname: '/rewards-manuales',
    },
    icon: EmojiEventsIcon,
    showCondition: true,
  },
  /* {
    label: 'Rewards automatizados',
    link: {
      pathname: '/rewards-automatizados',
    },
    icon: RedeemIcon,
    showCondition: true,
  }, */
  {
    label: 'Push notification',
    link: {
      pathname: '/notifications',
    },
    icon: NotificationsIcon,
    showCondition: true,
  },
  {
    label: 'Automatizacion',
    link: {
      pathname: '/notificaciones/automaticas',
    },
    icon: AutomationIcon,
    showCondition: true,
  },
  {
    label: 'Tags',
    link: {
      pathname: '/tags',
    },
    icon: LoyaltyIcon,
    showCondition: true,
  },
  {
    label: 'User Test',
    link: {
      pathname: '/user-test',
    },
    icon: AssignmentIcon,
    showCondition: !isEcard,
  },
  {
    label: 'Administradores',
    link: {
      pathname: '/admins',
    },
    icon: SupervisorAccountIcon,
    showCondition: true,
  },
  {
    label: 'Descargas',
    link: {
      pathname: '/downloads',
    },
    icon: ArrowDownward,
    showCondition: true,
  },
  {
    label: 'Dashboard',
    link: {
      pathname: '/dashboard',
    },
    icon: DashboardIcon,
    showCondition: true,
  },
  {
    label: 'Config. de estilo',
    link: {
      pathname: '/styles',
    },
    icon: StylesIcon,
    showCondition: true,
  },
]

const Drawer: React.FC = () => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)
  const [version, setVersion] = useState<string | null>(null)
  const [requestData, isRequestingData] = useApiCall<void, { version: string }>(getVersion)

  useEffect(() => {
    const updateVersion = async () => {
      const response = await requestData()
      setVersion(response.version)
    }

    if (version === null && !isRequestingData) {
      updateVersion()
    }
  }, [])

  const handleMouseEnter = () => {
    setIsExpanded(true)
  }

  const handleMouseLeave = () => {
    setIsExpanded(false)
  }

  return (
    <MaterialDrawer
      variant="permanent"
      className={`${classes.drawer} ${!isExpanded ? classes.drawerCollapsed : ''}`}
      classes={{
        paper: `${classes.drawerPaper} ${!isExpanded ? classes.drawerPaperCollapsed : ''}`,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.toolbar} />
      <List className={classes.list}>
        {drawerOptions
          .filter(e => e.showCondition)
          .map(option => (
            <NavLink
              exact
              to={option.link || ''}
              activeClassName={classes.activeLink}
              className={classes.link}
              key={option.label}
            >
              <ListItem button key={option.label}>
                <ListItemIcon className={!isExpanded ? classes.icon : ''}>
                  <option.icon />
                </ListItemIcon>
                {isExpanded && <ListItemText primary={option.label} />}
              </ListItem>
            </NavLink>
          ))}
      </List>
      {isExpanded && <Version version={version} />}
    </MaterialDrawer>
  )
}

export { Drawer }
